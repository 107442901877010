<template>
  <component :is="tag" :class="['collection-tabs', {'with-tabs': hasTabs}]">
    <div v-if="blok.title || subtitleRte" id="tablist-1" class="header">
      <h2 v-if="blok.title" class="size-m" v-html="blok.title"></h2>
      <div v-if="subtitleRte" class="size-s" v-html="subtitleRte"></div>
    </div>
    <div v-if="hasTabs" ref="manual" role="tablist" aria-labelledby="tablist-1" class="manual parent-bg">
      <button
        v-if="!isDesktop && hasScroll && (current > 0 || scrolling)"
        class="arrow left"
        :title="useMicroCopy('collectiontabs.arrowleft.title')"
        @click="scrollToTablistItem('prev')"
      >
        <SvgIcon name="func_left" />
      </button>
      <template v-if="isDesktop">
        <p v-if="blok.summary_title">{{ blok.summary_title }}</p>
        <div
          v-for="(item, index) in blok.items"
          :id="`nav_tabpanel-${index}`"
          :key="item._uid"
          class="tab"
        >
          <button
            :id="`tab-${index}`"
            type="button"
            role="tab"
            :aria-selected="index === 0"
            :aria-controls="`tabpanel-${index}`"
          >
            <span class="focus size-xl">
              {{ formatTabTitle(item.title) }}
            </span>
          </button>
          <p v-if="item.subtitle" class="size-xxs minor">
            {{ item.subtitle }}
          </p>
        </div>
      </template>
      <div
        v-show="!isDesktop"
        :class="`tablist-scroll${isOpened ? ' is-opened' : ''}`"
        @scroll="onTablistScroll()"
        @click="toggleMenu()"
      >
        <div
          v-for="(item, index) in blok.items"
          :id="`nav_tabpanel-${index}`"
          :key="item._uid"
          :class="`tab${index === current ? ' current-item' : ''}`"
          @click="scrollToTablistItem()"
        >
          <button
            :id="`tab-${index}`"
            type="button"
            role="tab"
            :aria-selected="index === 0"
            :aria-controls="`tabpanel-${index}`"
          >
            <span class="focus size-xl">
              {{ formatTabTitle(item.title) }}
            </span>
          </button>
        </div>
      </div>
      <button
        v-if="
          !isDesktop &&
          hasScroll &&
          (current < blok.items.length - 1 || scrolling)
        "
        class="arrow right"
        :title="useMicroCopy('collectiontabs.arrowright.title')"
        @click="scrollToTablistItem('next')"
      >
        <SvgIcon name="func_right" />
      </button>
    </div>
    <div :class="['collection-tabs-container', {'with-tabs': hasTabs}]">
      <template v-for="(item, index) in blok.items" :key="item._uid">
        <CollectionPanel :index="index" :blok="item" />
      </template>
    </div>
  </component>
</template>

<script setup>
import { useResizeObserver } from "@vueuse/core";
import TabsManual from "../utils/tabs";

const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});
const { blok } = toRefs(props);
const isDesktop = inject("isDesktop");
const isString =
  typeof blok.value.subtitle === "string" ||
  blok.value.subtitle instanceof String;
const subtitleRte = isString
  ? blok.value.subtitle
  : useRenderRichText(blok.value.subtitle);
let tablistScroll = null;
let scrolling = ref(false);
let hasScroll = ref(false);
let current = ref(0);
let allTabs = null;
let firstElt = null;
let lastElt = null;

onMounted(() => {
  initTabs();
  window.addEventListener("scroll", handleScroll);

  setTimeout(() => {
    window.scrollTo(0, 0);
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

const config = useRuntimeConfig();
const country = config.public["country"];

const formatTabTitle = (title) => {
  return country == "es" ? title[0].toUpperCase() + title.slice(1) : title;
};

const checkHasScroll = () => {
  const scrollWidth = tablistScroll.scrollWidth;
  const windowWidth = window.innerWidth;
  return scrollWidth > windowWidth;
};

const hasTabs = blok.value.items.length > 1;
const manual = ref(null);
useResizeObserver(manual, () => {
  initTabs();
});

const initTabs = () => {
  const tablists = document.querySelectorAll("[role=tablist].manual");
  for (var i = 0; i < tablists?.length; i++) {
    new TabsManual(tablists[i], isDesktop);
  }
  tablistScroll = document.querySelector(".tablist-scroll");
  if (tablistScroll) {
    hasScroll.value = checkHasScroll();
    allTabs = tablistScroll?.querySelectorAll(".tab");
    firstElt = allTabs[0];
    lastElt = allTabs[allTabs.length - 1];
  }
};

const scrollToTablistItem = (direction) => {
  let currentElt;
  let siblingElt;
  if (direction?.length) {
    current.value =
      direction === "next" ? current.value + 1 : current.value - 1;
    currentElt = document.querySelector(".current-item");
    siblingElt =
      direction === "next"
        ? currentElt?.nextElementSibling
        : currentElt?.previousElementSibling;
  } else {
    document.querySelector(".current-item").classList.remove("current-item");
    siblingElt = document.querySelector(".is-selected");
    siblingElt.classList.add("current-item");
  }
  tablistScroll.scrollTo({
    top: siblingElt?.offsetTop,
    left: siblingElt?.offsetLeft - 10,
    behavior: "smooth",
  });
};

const onTablistScroll = () => {
  setTimeout(() => {
    if (isInView(firstElt)) {
      current.value = 0;
      scrolling.value = false;
    } else if (isInView(lastElt)) {
      current.value = allTabs.length - 1;
      scrolling.value = false;
    } else {
      scrolling.value = true;
    }
  }, 400);
};

const isInView = (elt) => {
  const elementRect = elt.getBoundingClientRect();
  const parentRect = elt.parentNode.getBoundingClientRect();
  return (
    elementRect.right >= parentRect.left &&
    elementRect.left + elementRect.width <= parentRect.right
  );
};

let isOpened = ref(false);
const toggleMenu = () => {
  isOpened.value = !isOpened.value;
};

const handleScroll = () => {
  const genericPage = document.querySelector("#page-generic-main");
  if (genericPage) {
    const visiblePanelElt = document.querySelector('[data-is-visible="true"]');
    const navId = `#nav_${visiblePanelElt?.getAttribute("id")}`;
    let currentNavElt = null;
    const newElt = document.querySelector(navId);
    if (isDesktop.value) {
      currentNavElt = document.querySelector(".is-selected");
      currentNavElt?.classList.remove("is-selected");
      newElt?.classList.add("is-selected");
    } else {
      currentNavElt = document.querySelector(".current-item");
      currentNavElt?.classList.remove("current-item");
      newElt?.classList.add("current-item");
    }
    currentNavElt
      ?.querySelector("button")
      ?.setAttribute("aria-selected", "false");
    newElt?.querySelector("button")?.setAttribute("aria-selected", "true");
  }
};
</script>

<style lang="scss">
.background-collection-tabs {
  --parent-bg: var(--solid-07);

  &.alternate {
    background: var(--solid-01);
    --parent-bg: var(--solid-01);

    .arrow.left {
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 35.42%,
        var(--solid-01) 100%
      );
    }
    .arrow.right {
      background: linear-gradient(
        270deg,
        var(--solid-01) 35.42%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}
.header-facility .collection-tabs .manual {
  @include for-tablet-landscape-up {
    top: 160px !important;
  }
}
</style>

<style lang="scss" scoped>
.collection-tabs {
  position: relative;

  @include for-tablet-landscape-up {
    grid-template-rows: min-content min-content auto;
  }

  &.with-tabs {
    min-height: 100vh;
  }

  .header {
    grid-column: 2 / -2;
    grid-row: 1 / 2;
    margin-bottom: 2.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include for-tablet-landscape-up {
      max-width: 56.25rem;
      margin: 0 auto 6.25rem;
    }
  }

  .manual {
    position: sticky;
    z-index: 10;
    display: flex;
    top: 0;
    grid-column: 2/-2;
    background: transparent !important;
    @include pair-7;

    @include for-tablet-landscape-up {
      grid-row: 2 / 2;
      grid-column: 2 / span 6;
      height: calc(100vh - 3.5vw);
      flex-direction: column;
      overflow-y: auto;
      gap: 1.25rem;
      top: 69px;
      background: transparent;

      & > div {
        flex-grow: 0.001;
        transition: flex-grow 0.3s;
      }
    }

    [role="tab"] {
      white-space: nowrap;
      @include for-tablet-landscape-up {
        white-space: inherit;
      }
    }

    [role="tab"],
    [role="tab"]:focus,
    [role="tab"]:hover {
      display: inline-block;
      position: relative;
      z-index: 2;
      background: none;
      outline: none;
      font-weight: bold;
      overflow: hidden;
      text-align: left;
      cursor: pointer;
      appearance: none;
      border: none;
      padding: 0;
      color: var(--text-100);
    }

    [role="tab"][aria-selected="true"] {
      text-decoration: underline;
      text-underline-offset: 0.25rem;
      @include for-tablet-landscape-up {
        text-decoration: none;
      }
    }
    @include for-tablet-landscape-up {
      [role="tab"][aria-selected="true"] {
        &:before {
          content: ">";
          font-size: 2rem;
          vertical-align: super;
        }
      }
    }

    [role="tab"][aria-selected="false"] {
      opacity: 0.5;
      & + .minor {
        display: none;
      }
    }

    [role="tab"] span.focus {
      line-height: 125%;
      @include for-tablet-landscape-up {
        width: 90%;
      }
    }

    .minor {
      opacity: 0.7;
    }
  }

  .collection-tabs-container {
    grid-column: 2 / -2;
    overflow: auto;
    @include for-desktop-up {
      grid-column: 5 / -5;
    }
    &.with-tabs {
      @include for-tablet-landscape-up {
        grid-column: 8 / -2;
        grid-row: 2 /2;
      }
    }
  }

  .collection-tabs-item {
    visibility: visible;
    height: auto;
    opacity: 1;
    transition: opacity 1.2s linear;

    &.is-hidden {
      height: 0;
      padding-top: 0;
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 1.2s, opacity 1.2s linear;
    }
  }

  .with-tabs .collection-tabs-item {
    padding-top: 1.5rem;

    @include for-tablet-landscape-up {
      padding-top: 0;
    }
  }
}

.tablist-scroll {
  display: flex;
  gap: 0 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  padding-right: 2rem;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
}
.arrow {
  border: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0.2rem;
  width: 3.25rem;
  height: 3.25rem;
  display: flex;
  align-items: center;
  &.left {
    justify-content: flex-start;
    left: -0.4rem;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 35.42%,
      var(--solid-07) 100%
    );
  }
  &.right {
    justify-content: flex-end;
    right: -0.4rem;
    background: linear-gradient(
      270deg,
      var(--solid-07) 35.42%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
</style>
